import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { Auth0Provider } from "@auth0/auth0-react";
import store from './store'
import { Provider } from 'react-redux'

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";

ReactDOM.render(
  <Auth0Provider
    domain="auth.trelent.net"
    clientId="VRwMmoTKzbERO2rPlpx6XLLIOEwLgQVc"
    redirectUri={window.location.origin}
    audience="https://trelent.us.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
  >
    <Provider store={store}>
      <LayoutProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <App />
          </ThemeProvider>
      </LayoutProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
