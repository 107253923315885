import React from "react";
import {
  Button,
  Grid
} from "@material-ui/core";
import { useSelector } from 'react-redux'
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Plans(props) {
  var classes = useStyles();

  // global
  const userData    = useSelector((state) => state.userData.value);
  const accessToken = useSelector((state) => state.accessToken.value);

  const getCheckoutSession = async () => {
    const checkoutResponse = await fetch("https://trelent.npkn.net/create-checkout-session/", {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "customer_id": userData["StripeCustomerId"],
        "return_url": window.location.href
      })
    });

    if(checkoutResponse.status === 200) {
      checkoutResponse.json()
      .then(data => window.location.href = data["data"]);
    }
  }

  return (
    <>
      <PageTitle title="Welcome to Trelent!" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title="Thanks for signing up! Let's select a plan."
            upperTitle
            disableWidgetMenu
            bodyClass={classes.tableWidget}
          >
            <PricingTable highlightColor='#3198bb'>
              <PricingSlot highlighted title='Trelent Beta' priceText='$9.99/month' shouldDisplayButton={false}>
                <PricingDetail><b>All Supported Languages</b></PricingDetail>
                <PricingDetail><b>Unlimited Docstrings</b></PricingDetail>
                <PricingDetail><b>CLI Access</b></PricingDetail>
                <PricingDetail strikethrough><b>Data Residency Control</b></PricingDetail>
                <PricingDetail strikethrough><b>HIPAA & SOC 2 Compliance</b></PricingDetail>
                <PricingDetail strikethrough><b>Priority Support</b></PricingDetail>
                <br/>
                <PricingDetail><Button variant="contained" color="primary" onClick={getCheckoutSession}>Start Free Trial*</Button></PricingDetail>
                <br/>
              </PricingSlot>
              <PricingSlot title='Trelent Enterprise' priceText='$99.99/seat/mo' shouldDisplayButton={false}>
              <PricingDetail><b>All Supported Languages</b></PricingDetail>
                <PricingDetail><b>Unlimited Docstrings</b></PricingDetail>
                <PricingDetail><b>CLI Access</b></PricingDetail>
                <PricingDetail><b>Data Residency Control</b></PricingDetail>
                <PricingDetail><b>HIPAA & SOC 2 Compliance</b></PricingDetail>
                <PricingDetail><b>Priority Support</b></PricingDetail>
                <br/>
                <PricingDetail><Button variant="contained">Coming 2022</Button></PricingDetail>
                <br/>
              </PricingSlot>
            </PricingTable>
            *Free Trial only applies to new customers.
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}