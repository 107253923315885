import React from "react";
import {
  Button,
  Grid,
  Typography
} from "@material-ui/core";
import { useSelector } from 'react-redux'

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Keys(props) {
  var classes = useStyles();

  // global
  const userData    = useSelector((state) => state.userData.value)

  function copyToClipboard() {
    navigator.clipboard.writeText(userData["TrelentAPIKey"]);
  };

  return (
    <>
      <PageTitle title="API Keys" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            disableWidgetMenu
            bodyClass={classes.tableWidget}
          >
            <Typography variant="body1" style={{paddingTop: "5px"}}>
              API Key: 
              <code style={{backgroundColor: "#666", padding: "5px", marginLeft: "5px"}}>
                {userData["TrelentAPIKey"]}
              </code>
              <Button color="primary" style={{marginLeft: "12px"}} onClick={copyToClipboard} variant="contained">Copy</Button>
            </Typography>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}