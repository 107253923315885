import React from "react";
import {
  CircularProgress,
  Grid
} from "@material-ui/core";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Plans(props) {
  return (
    <>
      <PageTitle title="We're logging you in..." />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            disableWidgetMenu
          >
            <CircularProgress />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}