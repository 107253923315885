import React, { useEffect } from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, Link} from '@material-ui/core'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from 'react-redux'

import { setAccessToken } from '../../features/accessToken/accessTokenSlice'
import { setUserData } from '../../features/userData/userDataSlice'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import GetStarted from "../../pages/getstarted/GetStarted";
import Keys from "../../pages/keys";
import Plans from "../../pages/plans";
import Support from "../../pages/support";
import LoggingIn from "../../pages/loggingIn";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  const { user, getAccessTokenSilently } = useAuth0();

  // global
  const userData    = useSelector((state) => state.userData.value)
  const accessToken = useSelector((state) => state.accessToken.value)


  const dispatch = useDispatch()
  var layoutState = useLayoutState();


  useEffect(() => {
    const getAccesstoken = async () => {
      try {
        const tempAccessToken = await getAccessTokenSilently({
          audience: `https://docgen.trelent.net/`,
          scope: "read:current_user",
        });

        let accessToken = await tempAccessToken

        dispatch(setAccessToken(accessToken));
      } catch (e) {
        console.log(e.message);
      }
    };

    const getUserData = () => {
      try {
        fetch("https://trelent.npkn.net/get-user?user_id=" + encodeURIComponent(user.sub), {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }).then((response) => {
          return response.json();
        }).then((user_json) => {
          dispatch(setUserData(user_json));
        });
      } catch (e) {
        console.log(e.message);
      }
    };

    if(accessToken === null) {
      getAccesstoken();
      return;
    }

    if(userData["StripeCustomerId"] === null) {
      getUserData();
      return;
    }

  });

  if(userData["BillingPlan"] === null) {
    return (
      <div className={classes.root}>
          <Header history={props.history} />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <LoggingIn/>
          </div>
      </div>
    );
  }
  else if(userData["BillingPlan"] === 0) {
    return (
      <div className={classes.root}>
          <Header history={props.history} />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Plans/>
          </div>
      </div>
    );
  }

  console.log("Paid user!")

  return (
    <div className={classes.root}>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/start" component={GetStarted} />
            <Route path="/app/keys" component={Keys} />
            <Route path="/app/support" component={Support} />
          </Switch>
          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <div>
              <Link
                color={'primary'}
                href={'https://trelent.net'}
                target={'_blank'}
                className={classes.link}
              >
                About
              </Link>
              <Link
                color={'primary'}
                href={'https://status.trelent.net/status'}
                target={'_blank'}
                className={classes.link}
              >
                Status
              </Link>
            </div>
          </Box>
        </div>
    </div>
  );
}

export default withRouter(Layout);
