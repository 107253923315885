import React from "react";
import {
  Grid
} from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux'

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import GenDocString from "./components/GenDocString/GenDocString";

export default function Dashboard(props) {
  var classes = useStyles();

  // global
  const accessToken = useSelector((state) => state.accessToken.value)

  // local
  const { user } = useAuth0();
  
  return (
    <>
      <PageTitle title="Sandbox" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title="Generate Docstrings"
            upperTitle
            noBodyPadding
            disableWidgetMenu
            bodyClass={classes.tableWidget}
          >
            <GenDocString email={user.email} accessToken={accessToken} userId={user.sub} />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}