import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  Payment as PaymentIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux'

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

export default function Header(props) {
  var classes = useStyles();

  // global
  const userData    = useSelector((state) => state.userData.value);
  const accessToken = useSelector((state) => state.accessToken.value);
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  const { user, logout } = useAuth0();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  // Get a billing portal link
  const goToBillingPortal = () => {
    console.log("Retrieving portal link...");
    // Retrieve a billing portal session
    fetch('https://trelent.npkn.net/create-portal-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + accessToken
      },
      body: JSON.stringify({
        customer_id: userData["StripeCustomerId"],
        return_url: window.location.href
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.data) {
        window.location.href = data.data;
      }
    })
    .catch((error) => {
      console.error(error);
    });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          <img src="./LightWordmark.svg" alt="The name 'Trelent' spelt out in white text, with two blue dots next to the rightmost serif of the capital T" height="32px"/>
        </Typography>
        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              Hello, { user.nickname }
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={goToBillingPortal}
          >
            <PaymentIcon className={classes.profileMenuIcon} /> Billing
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
