import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    value: {
      "BillingPlan": null,
      "StripeCustomerId": null,
      "TrelentAPIKey": null,
    },
  },
  reducers: {
    setUserData: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserData } = userDataSlice.actions

export default userDataSlice.reducer