import {
  Grid,
} from "@material-ui/core";

// Contact form via form.io
import {Form} from '@formio/react';

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Support(props) {
  var classes = useStyles();

  return (
    <>
      <PageTitle title="Support" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title="Get in Touch"
            upperTitle
            disableWidgetMenu
            bodyClass={classes.tableWidget}
          >
            <Form src="https://urbdkpzwidnobxu.form.io/contact" onSubmit={console.log} />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}