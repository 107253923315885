import React from "react";
import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";

function Login(props) {
  var classes = useStyles();

  const { loginWithRedirect } = useAuth0();

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>DocGen</Typography>
        <Typography className={classes.subText}>Generating Documentation, Saving Dev Time.</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
          <Button className={classes.googleButton} size="large" variant="outlined" onClick={() => window.location.replace("https://forms.gle/5RNuHW8Pr12hTEfc8")}>
              Join Waitlist
            </Button>
            <Button className={classes.googleButton} size="large" variant="outlined" onClick={() => loginWithRedirect()}>
              Sign In
            </Button>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);