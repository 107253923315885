import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
} from "@material-ui/core";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { useState } from "react";



export default function GetStarted(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
        },
        button: {
          marginTop: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
        actionsContainer: {
          marginBottom: theme.spacing(2),
        },
        resetContainer: {
          padding: theme.spacing(3),
        },
    }));
    
    const classes = useStyles();

    const getSteps = () => {
        return ['Introduction', 'Trelent CLI', 'VS Code Extension', 'This WebApp'];
    }

    const getStepContent = (step) => {
        switch (step) {
          case 0:
            return `Trelent generates documentation for Python and Javascript functions. These 3 steps will show you how to use Trelent - both our webapp and VS Code extension.`;
          case 1:
            return `If you are using a paid plan, you have access to the Trelent CLI. Install it using <code>pip install trelent</code>,
                    and once installed authenticate your account using <code>trelent auth</code> and follow the prompts.<br/><br/>
                    
                    Once authenticated, run <code>trelent document [path to project folder]</code> to generate .diff files for your
                    entire project! If you want to insert docstrings directly into source immediately, use the <code>-i</code> flag.<br/><br/>
                    
                    You can find more <a style="color: #3198bb;" href="https://pypi.org/project/trelent/" target="_blank">documentation for the CLI here</a>.`;
          case 2:
            return `VS Code is the most widely-used IDE on the planet and Trelent supports it out of the box!<br/><br/>
                    
                    First, download our extension from the marketplace (just search for 'Trelent'), then once installed, execute the 'Trelent: Sign In' command from the command pallette.<br/><br/>
                    
                    Here, enter the API Key found on the 'API Keys' page just below this tutorial on the sidebar. Now, you're good to go!<br/><br/>
                    
                    Select the snippet of code you would like to generate documentation for, and press the key combination <code>Ctrl+Shift+2 // Cmd+Shift+2</code>. Alternatively, use the command pallete to run the command 'Trelent: Generate Docstrings'.<br/><br/>
                    
                    You can find <a style="color: #3198bb;" href="https://marketplace.visualstudio.com/items?itemName=Trelent.trelent" target="_blank">the extension here</a>.`;
          case 3:
            return `In our webapp, you don't need all the setup our extension requires. Simply copy and paste your code snippet into the provided editor on the 'Sandbox' page, select the language, and click 'Generate'.<br/><br/> 
            
                    5 docstrings will promptly be generated for you, right next to your original code snippet.`;
          default:
            return 'Unknown step';
        }
    }
    
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
  
  
    return (
    <>
      <PageTitle title="Get Started" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title="3 Simple Steps"
            upperTitle
            noBodyPadding
            disableWidgetMenu
          >
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                        <div dangerouslySetInnerHTML={{__html: getStepContent(index)}}></div>
                        <div className={classes.actionsContainer}>
                            <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>Tutorial completed - you&apos;re ready to go!</Typography>
                    <Button onClick={handleReset} className={classes.button} variant="outlined">
                        Restart
                    </Button>
                    <Button onClick={() => window.history.back()} color="primary" className={classes.button} variant="contained">
                        Let's Go!
                    </Button>
                </Paper>
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}