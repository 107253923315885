import React, { useState } from "react";
import { AppBar, Box, Button, CircularProgress, Grid, Tabs, Tab, Typography, NativeSelect } from "@material-ui/core";
import Editor from "@monaco-editor/react";

// components

// a11y props
function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function GenDocString(props) {
    var { accessToken, userId } = props;

    var editorRef = React.useRef(null);
    var docEditorRef = React.useRef(null);

    // local state
    const [tokenCount, setTokenCount] = useState(0);
    const [docStrings, setDocstrings] = useState([]);
    const [selectedDocstring, setSelectedDocstring] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState('python');
    

    const pythonSnippet = `def add_nums(nums):
   result = 0
   for num in nums:
      result += num
   return result`

    const javascriptSnippet = `function multNums(nums) {
   let result = 1;
   for(num of nums) {
      result *= num;
   }

   return result;
}`

    // Handle submission of a python snippet
    const submitDocStringRequest = () => {

        // Setup our data
        let req_body = {
            'language': language,
            'sender': 'web',
            'snippet': editorRef.current.getValue(),
            'user': userId,
        }

        // Generate our docstrings
        fetch('https://trelent.npkn.net/generate-docstring', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(req_body),
            mode: 'cors'
        })
        .then((response) => response.json())
        .then((data) => {
            // Now, show the options to the user by updating our docstrings state
            setIsLoading(false);

            // let regex = /:([^]*):/g

            let tempDocstrings = [];
            if(data.steps != null ) {
                for(var i = 0; i < 5; i++) {
                    let docstr = data.docstrings[i];

                    var newDocStr = docstr;
                    if(language === "python") {
                        newDocStr = '"""\n' + data.steps + docstr.slice(3);
                    }
                    else if(language === "javascript") {
                        newDocStr = '/**\n' + data.steps + docstr.slice(3);
                    }
                    else if(language === "java") {
                        newDocStr = '/**\n' + data.steps + docstr.slice(3);
                    }
                    else {
                        newDocStr = docstr;
                    }

                    tempDocstrings[i] = newDocStr;
                }
            }
            else {
                tempDocstrings = data.docstrings;
            }

            if(docEditorRef.current != null) {
                docEditorRef.current.setValue(tempDocstrings[selectedDocstring]);
            }

            setDocstrings(tempDocstrings);
        })
        .catch((error) => {
            console.error(error);
        });

        setIsLoading(true);
    }

    const handleEditorChange = (value) => {
        if(value != null) {
            // Check how many tokens this input will be
            var token_approx = Math.ceil(value.length * 1.1 / 4);

            // Set our state to reflect the new token count
            setTokenCount(token_approx);
        }
    }

    const handleEditorDidMount = (editor, monaco) => {
        // here is the editor instance
        // you can store it in `useRef` for further usage
        editorRef.current = editor;
    }

    const handleDocEditorChange = (event) => {
        if(!event.isFlush) {
            // Manual change was made

            var newDocStrings = docStrings;
            newDocStrings[selectedDocstring] = event.changes[0].text;
            setDocstrings(newDocStrings);
        }
    }

    const handleDocEditorDidMount = (editor, monaco) => {
        // here is the editor instance
        // you can store it in `useRef` for further usage
        docEditorRef.current = editor;
    }

    const useDocstring = () => {
        const concatenatedString = docStrings[selectedDocstring] + '\n' + editorRef.current.getValue();
        editorRef.current.setValue(concatenatedString);
    }


    const handleTabChange = (event, newValue) => {
        docEditorRef.current.setValue(docStrings[newValue]);
        setSelectedDocstring(newValue);
    };

    // Define whether or not to use regen vs gen terminology
    const genOrRegen = () => {
        if(docStrings != null && docStrings.length > 0) {
            return "Re-Generate Docstrings";
        }
        else {
            return "Generate Docstrings";
        }
    }

    const getSnippet = () => {
        
        // Check selected language, default to Python
        if(language === "python") {
            return pythonSnippet;
        }
        else if(language === "javascript") {
            return javascriptSnippet;
        }
        else {
            return pythonSnippet;
        }
    }

    const changeLang = (event) => {
        setLanguage(event.target.value);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" style={{paddingLeft: "24px"}}>
                    Add your
                    <NativeSelect
                        value={language}
                        onChange={changeLang}
                        style={{marginLeft: "8px", marginRight: "8px"}}
                        inputProps={{
                            name: 'language',
                            id: 'language-native-helper',
                        }}
                    >
                        <option value={"python"}>Python</option>
                        <option value={"javascript"}>Javascript</option>
                    </NativeSelect>
                    function below.
                </Typography>
            </Grid>
            <Grid item xs={12} md={(docStrings != null && docStrings.length > 0) ? 6 : 12}>
                <Box m={3}>
                    <Editor
                        height="50vh"
                        defaultLanguage="python"
                        language={language}
                        defaultValue={"def add_nums(nums):\n   result = 0\n   for num in nums:\n      result += num\n   return result"}
                        value={getSnippet()}
                        onMount={handleEditorDidMount}
                        onChange={handleEditorChange}
                        theme="vs-dark"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                {
                    docStrings != null && docStrings.length > 0 && (
                        <Box m={3}>
                            <AppBar position="static" color="default" style={{height: "5vh"}}>
                                <Tabs
                                    value={selectedDocstring}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="tabs to select docstring to use"
                                >
                                    <Tab label="1" {...a11yProps(0)} />
                                    <Tab label="2" {...a11yProps(1)} />
                                    <Tab label="3" {...a11yProps(2)} />
                                    <Tab label="4" {...a11yProps(3)} />
                                    <Tab label="5" {...a11yProps(4)} />
                                </Tabs>
                            </AppBar>
                            <Editor
                                height="45vh"
                                language={language}
                                defaultValue={docStrings[0]}
                                onMount={handleDocEditorDidMount}
                                onDidChangeModelContent={handleDocEditorChange}
                                theme="vs-dark"
                            />
                        </Box>
                    )
                }
            </Grid>
            <Grid item xs={12}  md={(docStrings != null && docStrings.length > 0) ? 6 : 12}>
                <Box p={3}>
                    {
                        isLoading === false && tokenCount < 2048 && (
                            <Button
                                variant="outlined"
                                onClick={submitDocStringRequest}
                            >
                                { genOrRegen() }
                            </Button>
                        )
                    }
                    {
                        isLoading === true && (
                            <CircularProgress />
                        )
                    }
                    {
                        (tokenCount >= 2048) && (
                            <Button
                                variant="outlined"
                                onClick={submitDocStringRequest}
                                disabled={true}
                            >
                                { genOrRegen() }
                            </Button>
                        )
                    }
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box p={3}>
                    {
                    docStrings != null && docStrings.length > 0 && (
                        <Button
                            variant="outlined"
                            onClick={useDocstring}
                        >
                            Use Docstring
                        </Button>
                        )
                    }
                </Box>
            </Grid>
        </Grid>
    );
}